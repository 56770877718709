import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

export default function Product(pageData) {
  const group = pageData.pageContext.group;
  const allProducts = pageData.pageContext.allProducts;
  const thisGroupProducts = allProducts.filter(p => p.grupa_path === group.group);


  const TabelaProduktowGrupy = () => {

    return (
      <div className="table-responsive-sm">
{/*         {thisGroupProducts.map((p) => {
          return (
            <a href="#" className="row clickable ">
              <div className="col" class="4 py-2">{p.produkt_nazwa}</div>
              <div className="col" class="2 py-2">{p.oferta_U}V</div>
            </a>
          );
        })} */}

        {/*         <pre>{JSON.stringify(thisGroupProducts)}</pre> */}
        <table className="table table-hover">
          <thead>
            <tr className="table-secondary">
              <th scope="col" class="small text-start">Typ</th>
              <th scope="col" class="small">Długość</th>
              <th scope="col" class="small">Szerokość</th>
              <th scope="col" class="small">Wysokość</th>
              <th scope="col" class="small">Pojemność</th>              
              <th scope="col" class="small">Napięcie</th>              
            </tr>
          </thead>
          <tbody>
            {thisGroupProducts.map((p) => {
              return (
               
                  <tr key={p.slug}>
                    <td class="text-start">
                      <Link to={`/${p.slug}/`} className="text-secondary text-decoration-none">
                        <b>{p.produkt_nazwa}</b>
                      </Link>
                    </td>
                    <td>{p.p_arr_all.oferta_dlug} mm</td>
                    <td>{p.p_arr_all.oferta_szer} mm</td>
                    <td>{p.p_arr_all.oferta_wys} mm</td>
                    <td><b>{p.oferta_poj}</b> Ah</td>                  
                    <td>{p.oferta_U} V</td>                  
                  </tr>
               
              );
            })}
          </tbody>
          <caption></caption>
        </table>
      </div>
    );
  }

  return (
    <Layout>
      <Seo title={`Akumulatory ${group.group}`} />
      <section className="py-5 text-center container">
        <div className="row py-lg-5">
          <div className="col lg-6 col md-8 mx-auto">
            <h1 className="fw-light">{`Akumulatory B.B. Battery ${group.group.toUpperCase()}`}</h1>
            <p className="text-muted">Akumulatory o przedłużonej żywotności, przeznaczone do zastosowania w zasilaniu awaryjnym.</p>
            <TabelaProduktowGrupy />
{/*             <ul>
              {thisGroupProducts.map((p) => {
                return (
                  <li>
                    <Link to={`/akumulator/${p.slug}`}>
                      B.B. Battery {p.produkt_nazwa}
                    </Link>{" "}
                    <b>{p.oferta_U}</b>V <b>{p.oferta_poj}</b>Ah
                  </li>
                );
              })}
            </ul> */}
          </div>
        </div>
        <div className="row">

        </div>
      </section>
    </Layout>
  );
}
